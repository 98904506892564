<template>
  <v-container fluid>
    <v-divider class="my-5"></v-divider>
    <v-card>
      <v-row>
        <v-col cols="8">
          <h1>PRUEBAS BODA</h1>
          <v-simple-table v-if="datosPb && datosPb.length > 0" style="width: max-content">
            <thead>
              <tr>
                <th>
                  <v-text-field
                    style="display:inline-block;max-width: 280px;"
                    v-model="seleccionadoPb"
                    class="v-superdense-input mt-2"
                    @input="getPb"
                    label="Año"
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </th>
                <th>PB PENDIENTES</th>
                <th>% PTES</th>
                <th>TOTAL BODAS</th>
                <th>PB CON FECHA</th>
                <th>PB EFECTUADAS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(y,i) in datosPb">
                <td>{{ y.y }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center;font-size: large;font-weight: bold;" class="error--text">{{ (y.bodas - y.pb) || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center;font-size: large;font-weight: bold;" class="error--text">{{ Math.round(((y.bodas - y.pb) / y.bodas) * 100) || 0 }}%</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center;">{{ y.bodas || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ y.pb || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ y.pbEf || 0 }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="4">
          <canvas id="graph4"></canvas>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { parseDate } from "@/utils";

export default {
  data() {
    return {
      meses:["ENE","FEB","MAR","ABR","MAY","JUN","JUL","AGO","SEP","OCT","NOV","DIC"],
      tabsEv: 0,
      menuLeads: false,
      datosPb: [],
      seleccionadoPb: new Date().getFullYear(),
      chart4: null,
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
    };
  },
  mounted(){
    this.getPb()
  },
  methods: {
    async getPb() {
      if(this.seleccionadoPb == null || isNaN(this.seleccionadoPb) || String(this.seleccionadoPb).length != 4) return;
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/pb/${this.seleccionadoPb}`,
      });

      this.datosPb = data.map((m) => {
        return m;
      });
      const self = this

      if(this.chart4) this.chart4.destroy()
      
      this.chart4 = new Chart(document.getElementById("graph4"), 
        {
          type: 'bar',
          data: {
            labels: this.datosPb.filter(p => p.y == new Date().getFullYear()).map(r => r.y),
            datasets: [
              {
                label: 'PRUEBAS BODA CON FECHA',
                data: this.datosPb.filter(p => p.y == new Date().getFullYear()).map(r => (r.pb || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: 'PRUEBAS BODA PENDIENTES',
                data: this.datosPb.filter(p => p.y == new Date().getFullYear()).map(r => {return r.bodas - r.pb}),
                fill: false,
                backgroundColor: [
                  this.specialColor['warning']
                ],
                borderColor: [
                  this.specialColorBorder['warning']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['warning'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return 'PRUEBAS BODA PENDIENTES: ' + context.formattedValue + ' (' + (((self.datosPb[context.dataIndex].bodas - self.datosPb[context.dataIndex].pb) / (self.datosPb[context.dataIndex].bodas || 1)) * 100) + '%)'
                    }
                  }
                },
              }
            ],
            options: {
              scales: {
                y: {
                  beginAtZero: false,
                  ticks: {
                    stepSize: 5,
                    display: true
                  }
                }
              }
            }
          },
          plugins: [ChartDataLabels],
        }
      )
      this.loading = false;
    },
  },
};
</script>